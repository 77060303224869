// Libs
import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router';
 
//  Services
import API from 'API';

// Components
import Layout from 'components/layout/Layout';
import Loader from 'components/common/Loader';
import Login from 'components/Login';

// Components - pages
import ListCustomers from 'components/customer/ListCustomers';
import EditCustomer from 'components/customer/EditCustomer';
import ListJobs from 'components/job/ListJobs';
import EditJob from 'components/job/EditJob';
import ListSuppliers from 'components/supplier/ListSuppliers';
import EditSupplier from 'components/supplier/EditSupplier';
import ListPurchaseOrders from 'components/purchase-order/ListPurchaseOrders';
import EditPurchaseOrder from 'components/purchase-order/EditPurchaseOrder';
import ListJobDeliveries from 'components/job-delivery/ListJobDeliveries';
import EditJobDelivery from 'components/job-delivery/EditJobDelivery';
import ListPurchaseOrderDeliveries from 'components/purchase-order-delivery/ListPurchaseOrderDeliveries';
import EditPurchaseOrderDelivery from 'components/purchase-order-delivery/EditPurchaseOrderDelivery';
import OpenOrdersPage from "./components/open-orders/OpenOrdersPage";
import ThirdPartyPage from "./components/third-party/ThirdPartyPage";

//-------------------------------------------------------------------------------------------------------------------

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            loginInfo: null
        };

        this.checkLogin = this.checkLogin.bind(this);
    }

    async componentDidMount() {
        this.checkLogin();
    }

    async checkLogin() {
        // Determine if logged in
        let loginInfo = null;
        try {
            loginInfo = await API.call('account/get-login-info');
        } catch (e) {
            // Ignore error - loginInfo will be null
        }

        // Update UI
        this.setState({
            loginInfo,
            isLoading: false
        });
    }
    
    //-------------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading,
            loginInfo
        } = this.state;
        const rootNode = document.querySelector('#root');

        // Loading login status
        if (isLoading) {
            return (<Loader />);
        }

        // Not logged in yet
        if (!loginInfo || !loginInfo.user) {
            rootNode.classList.add('not-logged-in');
            return this.renderNotLoggedIn();
        }

        // Logged in
        rootNode.classList.remove('not-logged-in');
        return this.renderLoggedIn();
    }

    renderNotLoggedIn() {
        return (
            <Login
                onLogIn={this.checkLogin}
            />
        );
    }

    renderLoggedIn() {
        const getChildProps = (props) => ({
            ...props.match.params,
            history: props.history
        });
        return (
            <Layout>
                <>
                    {/* Customers */}
                    <Route path="/customers/:page?" render={(props) =>
                        <ListCustomers {...getChildProps(props)} />}
                    />
                    <Route path="/customer/:id?" render={(props) =>
                        <EditCustomer {...getChildProps(props)} />}
                    />

                    {/* Jobs */}
                    <Route path="/jobs/:page?" render={(props) =>
                        <ListJobs {...getChildProps(props)} />}
                    />
                    <Route path="/job/:id?/:status?" render={(props) =>
                        <EditJob {...getChildProps(props)} />}
                    />

                    {/* Sales orders */}
                    <Route path="/sales-orders" render={(props) =>
                        <ListJobs {...getChildProps(props)} page="SalesOrder" />}
                    />
                    <Route path="/sales-order/:id?/:status?" render={(props) =>
                        <EditJob {...getChildProps(props)} />}
                    />

                    {/* Suppliers */}
                    <Route path="/suppliers/:page?" render={(props) =>
                        <ListSuppliers {...getChildProps(props)} />}
                    />
                    <Route path="/supplier/:id?" render={(props) =>
                        <EditSupplier {...getChildProps(props)} />}
                    />

                    {/* Purchase Orders */}
                    <Route path="/purchase-orders/:page?" render={(props) =>
                        <ListPurchaseOrders {...getChildProps(props)} />}
                    />
                    <Route path="/purchase-order/:id?/:status?" render={(props) =>
                        <EditPurchaseOrder {...getChildProps(props)} />}
                    />

                    {/* Job Deliveries */}
                    <Route path="/job-deliveries/:page?" render={(props) =>
                        <ListJobDeliveries {...getChildProps(props)} />}
                    />
                    <Route path="/job-delivery/:id?/:jobID?" render={(props) =>
                        <EditJobDelivery {...getChildProps(props)} />}
                    />

                    {/* PO Deliveries */}
                    <Route path="/purchase-order-deliveries/:page?" render={(props) =>
                        <ListPurchaseOrderDeliveries {...getChildProps(props)} />}
                    />
                    <Route path="/purchase-order-delivery/:id?/:purchaseOrderID?" render={(props) =>
                        <EditPurchaseOrderDelivery {...getChildProps(props)} />}
                    />

                    {/*OpenOrder*/}
                    <Route path="/open-orders/:page?" render={(props) =>
                        <OpenOrdersPage {...getChildProps(props)} /> }
                    />
                    
                    {/*ThirdParties*/}
                    <Route path="/third-parties/:page?" render={(props) => 
                        <ThirdPartyPage {...getChildProps(props)} /> }
                    />
                </>
            </Layout>
            
        );
    }
}

export default withRouter(App);
