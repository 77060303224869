import React from 'react';
import { Link } from 'react-router-dom';

// Services
import API from 'API';

// Components
import SuperTable from 'components/common/SuperTable';
import Loader from 'components/common/Loader';
import PageList from 'components/common/PageList';

//-----------------------------------------------------------------

class ListJobs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            jobs: [],
            pages: [
                { id: 'open', label: 'Open' },
                { id: 'closed', label: 'Closed' }
            ]
        };
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(oldProps) {
        if (this.props.page != oldProps.page) {
            this.load();
        }
    }

    async load() {
        const { page } = this.props;
        this.setState({ isLoading: true });
        const jobs = (await API.call('job/list', { status: page })) || [];
        this.setState({
            isLoading: false,
            jobs
        });
    }

    open(id) {
        const url = (this.props.page == 'SalesOrder' ? `/sales-order/${id}` : `/job/${id}`);
        this.props.history.push(url);
    }

    //----------------------------------------------------------------------

    render() {
        const {
            pages
        } = this.state;
        const { page } = this.props;

        return (<>

            <section className="control-panel">

                {this.props.page == 'SalesOrder' ?
                    <>
                        <Link className="ms-auto btn btn-primary" to="/job/0/SalesOrder">
                            New sales order
                        </Link>
                    </> :
                    <>
                        <PageList
                            pages={pages}
                            page={page}
                            getURL={(id) => `/jobs/${id}`}
                        />

                        <Link className="ms-auto btn btn-primary" to="/job/0/Open">
                            New job
                        </Link>
                    </>
                }

            </section>

            <section>

                {this.renderTable()}

            </section>

        </>);
    }

    renderTable() {
        const {
            isLoading,
            jobs
        } = this.state;
        const { page } = this.props;

        if (isLoading) {
            return (<Loader />);
        }
        
        let typeName;
        if (page == 'SalesOrder') {
            typeName = 'sales orders';
        } else {
            typeName = (page || '').toLowerCase() + ' jobs';
        }

        const cols = {
            reference: {
                label: 'Job Number',
                className: 'ref-col'
            },
            customerName: {
                label: 'Customer',
                className: 'customer-name-col',
                getValue: (colInfo, job) => (job.customer ? job.customer.name : '')
            },
            poNumber: {
                label: 'PO Number',
                className: 'po-number-col'
            },
            total: {
                label: 'Total',
                type: 'currency',
                className: 'total-col'
            },
            quantityOrdered: {
                label: 'Ordered',
                className: 'quantity-ordered-col'
            },
            quantityDispatched: {
                label: 'Dispatched',
                className: 'quantity-dispatched-col'
            }
        };
        
        return (
            <SuperTable
                className="jobs-table table table-bordered mb-0"
                rows={jobs}
                keyAccessor={job => job.id}
                cols={cols}
                onClick={(job, e) => this.open(job.id)}
                emptyText={page ? `No ${typeName} to show` : 'Please select a page'}
            />
        );
    }
}

export default ListJobs;